import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from "@sentry/react";
import { Integrations } from '@sentry/tracing';
import * as Sentry from '@sentry/browser';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';
import App from './components/app/App';
//import * as serviceWorker from './serviceWorker';

Sentry.init({
  dsn: `${process.env.REACT_APP_SentryDSN}`,
  autoSessionTracking: true,
  environment: `${process.env.REACT_APP_env}`,
  release: `${process.env.REACT_APP_SentryRelease + process.env.REACT_APP_myBNIversion}`,
  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsoleIntegration({
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ['error'],
    }),
  ],
  tracesSampleRate: `${process.env.REACT_APP_SentrySampleRate}`,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
