import React from 'react';
import Http from '../../services/http';
import { dataSources } from '../../utils/data-sources';
import { AppLayout } from '../../components/app-layout';
import { TileLayout } from '../../components/tile-layout';
import Alert from '@material-ui/lab/Alert';
import { ThemeProvider } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { HandleErrors } from '../../components/handle-errors';
import { Cookies } from 'react-cookie';
import { FooterMenu } from '../../components/footer-menu';
import * as staticTextKey from '../../utils/translation-variable';
import theme from '../../utils/theme';

export class LauncherPage extends React.Component {
  dataSources = dataSources();
  http = Http.getInstance();
  state = {
    toTerms: true,
    loading: false,
    apps: [],
    errorDisplay: false,
    errorResponse: {},
    noAppsAssignedValue: '',
    logoutValue: '',
    launcherTermsAccepted: true,
    transApiData: '',
    showAlert: true,
  };

  async componentDidMount() {
    if (document.cookie.split(';').filter(item => item.includes('access_token')).length) {
      await this.getTranslationApiStatusData();
      await this.getStatusData();
      this.setState(
        {
          loadData: true,
        },
        () => this.loadData(),
      );
    } else {
      window.location.href = `${process.env.REACT_APP_myBNIURL}`;
    }

    if (sessionStorage.getItem('translations') == null) {
      this.getTranslationData();
    }
  }

  async loadData() {
    await this.launcherData();
    await this.getTranslationData();
    await this.getTranslationDataMFA();
    await this.appAccess();
    await this.termsData();
  }

  getTranslationApiStatusData = () => {
    return this.http
      .getTranslationApiStatusData()
      .then(res => {
        if (res.status === 200) {
          sessionStorage.setItem('translationApiStatusData', JSON.stringify(res.data));
          this.setState({
            transApiData: JSON.stringify(res.data),
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  getStatusData = () => {
    return this.http
      .getPortalApiStatusData()
      .then(res => {
        if (res.status === 200) {
          sessionStorage.setItem('ApiStatusData', JSON.stringify(res.data));
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  appAccess() {
    const cookies = new Cookies();
    let IAM_id = cookies.get('IAM_id');
    let appId;
    appId = process.env.REACT_APP_BNIPortalId;
    let url = `${this.dataSources.users}/${IAM_id}/apps/${appId}`;

    return this.http
      .getData(url, '', '')
      .then(res => {
        if (res.status === 200) {
        } else {
          this.setState({
            ready: true,
            errorDisplay: true,
            errorResponse: res.response,
          });
        }
      })
      .catch(error => {
        this.setState({
          ready: true,
          errorDisplay: true,
          errorResponse: error.response,
        });
      });
  }

  termsData() {
    const cookies = new Cookies();
    let IAM_id = cookies.get('IAM_id');
    let appId;
    let launcherTermsStatus;
    appId = process.env.REACT_APP_BNIPortalId;

    let url = `${this.dataSources.users}/${IAM_id}/apps/${appId}/tos`;
    // const url = `${this.dataSources.user_apps}`
    return this.http
      .getData(url, '', '')
      .then(res => {
        if (res.status === 200) {
          launcherTermsStatus = res.data.status;
          this.setState({
            ready: true,
            launcherTermsAccepted: launcherTermsStatus,
            appId: appId,
          });
        } else {
          this.setState({
            ready: true,
            errorDisplay: true,
            errorResponse: res.response,
          });
        }
      })
      .catch(error => {
        this.setState({
          ready: true,
          errorDisplay: true,
          errorResponse: error.response,
        });
      });
  }

  getTranslationData = () => {
    let locale = this.http.getLocale();
    const cookies = new Cookies();
    let bniLang = cookies.get('bni_language_select');

    let lang = bniLang !== undefined ? bniLang : locale.lang_code;
    axios({
      method: 'post',
      url: `${this.dataSources.translateApi}/${lang}/search`,
      data: {
        has_translation: true,
        group_code: 'portal.login',
        page_size: 100,
      },
      headers: {
        'Content-Type': 'application/json',
        client_name: `${process.env.REACT_APP_clientName}`,
        client_secret: `${process.env.REACT_APP_clientSecret}`,
        'x-iso-ids-only': 'N',
      },
    })
      .then(response => {
        const translationRawData = response.data.translations;

        let portaltranslation = {};
        translationRawData.map(function (row) {
          portaltranslation[row.lang_key] = row.lang_value ? row.lang_value : row.en_val;
        });
        //handle success
        sessionStorage.setItem('lang', JSON.stringify(lang));
        sessionStorage.setItem('translations', JSON.stringify(portaltranslation));

        this.setState({
          noAppsAssignedValue: portaltranslation[staticTextKey.noAppsAssignedValue],
          logoutValue: portaltranslation[staticTextKey.logoutValue],
          copyrightValue: portaltranslation[staticTextKey.copyrightValue],
          ppValue: portaltranslation[staticTextKey.ppValue],
          infoValue: portaltranslation[staticTextKey.infoValue],
        });
      })
      .catch(error => {
        //handle error
        console.log(error);
      });
  };

  getTranslationDataMFA = () => {
    let locale = this.http.getLocale();
    const cookies = new Cookies();
    let bniLang = cookies.get('bni_language_select');

    let lang = bniLang !== undefined ? bniLang : locale.lang_code;
    axios({
      method: 'post',
      url: `${this.dataSources.translateApi}/${lang}/search`,
      data: {
        has_translation: true,
        group_code: 'portal-mfa',
        page_size: 100,
      },
      headers: {
        'Content-Type': 'application/json',
        client_name: `${process.env.REACT_APP_clientName}`,
        client_secret: `${process.env.REACT_APP_clientSecret}`,
        'x-iso-ids-only': 'N',
      },
    })
      .then(response => {
        const translationRawData = response.data.translations;

        let portalMFAtranslation = {};
        translationRawData.map(function (row) {
          portalMFAtranslation[row.lang_key] = row.lang_value ? row.lang_value : row.en_val;
        });
        console.log("newdata",portalMFAtranslation);
        //handle success
        sessionStorage.setItem('lang', JSON.stringify(lang));
        sessionStorage.setItem('translations', JSON.stringify(portalMFAtranslation));

        localStorage.setItem('lang', JSON.stringify(lang));
        localStorage.setItem('translations', JSON.stringify(portalMFAtranslation));

        this.setState({
          headerMFAtext: portalMFAtranslation[staticTextKey.headerMFAtext],
          headerMFAlink: portalMFAtranslation[staticTextKey.headerMFAlink],
          headerMFAtext2: portalMFAtranslation[staticTextKey.headerMFAtext2],
        });
      })
      .catch(error => {
        //handle error
        console.log(error);
      });
  };

  launcherData() {
    const cookies = new Cookies();
    let IAM_id = cookies.get('IAM_id');
    // const url = `${this.dataSources.user_apps}`
    let url = `${this.dataSources.users}/${IAM_id}`;
    return this.http
      .getData(url, '', '')
      .then(res => {
        if (res.status === 200) {
          //get apps array data
          let apps = res.data[0].apps;
          //get accepted tos array data
          let accepted_tos = res.data[0].accepted_tos;

          //call tosStatus function to check if all assigned apps have tos accepted
          apps.forEach(this.tosStatus, accepted_tos);
          this.setState({
            loadData: true,
            apps,
            errorDisplay: false,
            errorResponse: res.data,
          });
          localStorage.setItem('apps', JSON.stringify(apps));
          localStorage.setItem('errorDisplay', false);
          localStorage.setItem('error', res.data);
        } else {
          this.setState({
            loadData: true,
            errorDisplay: true,
            errorResponse: res.response,
          });
          localStorage.setItem('errorDisplay', true);
          localStorage.setItem('error', res.response);
        }
      })
      .catch(error => {
        console.log(error);
        this.setState({
          loadData: true,
          errorDisplay: true,
          errorResponse: error.response,
        });
      });
  }

  tosStatus(item, index) {
    //get active tos id of specific app passed
    let appActiveTosId = item.active_tos_ids._id;
    // get array of accepted Tos and fetch an array of its ids.
    let acceptedTos = this;
    let acceptedTosId = acceptedTos.map(a => a._id);

    //check if specific app active Tos id is present in array of accepted Tos.
    let activeTosStatus = acceptedTosId.includes(appActiveTosId);

    // pass status as value to tos_acceptance_status key either true or false
    item.tos_acceptance_status = activeTosStatus;
  }

  render() {
    const translationsData = JSON.parse(sessionStorage.getItem('translations'));
    const cookie = new Cookies();
    const authorised_user = cookie.get('authorised_user');
    if (authorised_user === 'true') {
      if (this.state.launcherTermsAccepted === false) {
        return (
          <Redirect
            to={{
              pathname: '/terms',
              state: {
                launcherTermsAccepted: this.state.launcherTermsAccepted,
                appId: this.state.appId,
              },
            }}
          />
        );
      }
      if (this.state.loadData) {
        return (
          <React.Fragment>
            <ThemeProvider theme={theme}>
              <div dir="ltr">
                <div className="super-graphic-bg">
                  <AppLayout
                    apps={this.state.apps}
                    errorDisplay={this.state.errorDisplay}
                    errorResponse={this.state.errorResponse}
                    logoutValue={this.state.logoutValue}
                  />

                  {this.state.showAlert && cookie.get('mfa_enabled') === 'false' && (
                    <Alert
                      className={'alertMsg'}
                      icon={false}
                      onClose={() => {
                        this.setState({ showAlert: false });
                      }}>
                      {this.state.headerMFAtext}{' '}
                      <a className="mfaLink" href="/myaccount/mfa/settings">
                        {this.state.headerMFAlink}
                      </a>{' '}
                      {/* {this.state.headerMFAtext2} */}
                    </Alert>
                  )}

                  {!this.state.errorDisplay && (
                    <TileLayout
                      apps={this.state.apps}
                      noAppsAssignedValue={this.state.noAppsAssignedValue}
                    />
                  )}

                  {this.state.errorDisplay && <HandleErrors errorData={this.state.errorResponse} />}
                  <FooterMenu
                    copyrightValue={this.state.copyrightValue}
                    ppValue={this.state.ppValue}
                    infoValue={this.state.transApiData}
                  />
                </div>
              </div>
            </ThemeProvider>
          </React.Fragment>
        );
      } else {
        return <div>&nbsp;</div>;
      }
    } else {
      window.location.href = '/';
    }
  }
}
