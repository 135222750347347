const env = process.env.NODE_ENV || 'development';
function getEndpoints() {
  return {
    login: `${process.env.REACT_APP_BNIPortalAPI}/auth/login`,
    // user_apps: `${process.env.REACT_APP_myBNIAPIURL}tos/fetch_user_apps`,
    // accept_tos:`${process.env.REACT_APP_API_SERVER_accept_tos}`,
    // acceptance_check:`${process.env.REACT_APP_API_SERVER_acceptance_check}`,
    // status: `${process.env.REACT_APP_myBNIAPIURL}status/ping`,
    translationLanguageStatus: `${process.env.REACT_APP_BNITranslationApi}/status/ping`,
    refreshToken: `${process.env.REACT_APP_BNIPortalAPI}/auth/token/refresh`,
    // logoutAccess: `${process.env.REACT_APP_myBNIAPIURL}auth/logout_access`,
    // logoutRefresh: `${process.env.REACT_APP_myBNIAPIURL}auth/logout_refresh`,
    translationSearch: `${process.env.REACT_APP_BNITranslationApi}/search`,
    translationLanguage: `${process.env.REACT_APP_BNITranslationApi}/language`,
    translateApi: `${process.env.REACT_APP_I18NAPI}/translations`,
    i18nstatus: `${process.env.REACT_APP_I18NAPI}/status`,
    users: `${process.env.REACT_APP_BNIPortalAPI}/users`,
    auth: `${process.env.REACT_APP_BNIPortalAPI}/auth`,
    apps: `${process.env.REACT_APP_BNIPortalAPI}/apps`,
    tos: `${process.env.REACT_APP_BNIPortalAPI}/tos`,
    logout: `${process.env.REACT_APP_BNIPortalAPI}/auth/logout`,
    status: `${process.env.REACT_APP_BNIPortalAPI}/status`,
    languages: `${process.env.REACT_APP_I18NAPI}/languages`,
    
    mfaTypes: `${process.env.REACT_APP_BNIPortalAPI}/users/mfa/type`,
    mfaMethods: `${process.env.REACT_APP_BNIPortalAPI}/users/mfa`,
    qrCode: `${process.env.REACT_APP_BNIPortalAPI}/totp/qrcode`,
    validateTotp: `${process.env.REACT_APP_BNIPortalAPI}/totp/validate`,
    sendOtp: `${process.env.REACT_APP_BNIPortalAPI}/otp/send`,
    validateOtp: `${process.env.REACT_APP_BNIPortalAPI}/otp/validate`
  };
}

const dsMap = {
  development: getEndpoints(),
  test: getEndpoints(),
  production: getEndpoints(),
};

export const dataSources = () => dsMap[env];
