import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
// import * as staticTextKey from '../../utils/translation-variable';
import userLogo from '../static/imgs/userLogo.png';
import phoneIcon from '../static/imgs/phone.png';
import Http from '../services/http';
import { dataSources } from '../utils/data-sources';
import { TextField, Button } from '@material-ui/core';
import { FooterMenu } from '../components/footer-menu';
import { AppLayout } from '../components/app-layout';
import theme from '../utils/theme';
import { Cookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import PhoneInput, { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';
// import Input , {isPossiblePhoneNumber, isValidPhoneNumber} from 'react-phone-number-input/input'

export function SMS(props) {
  const cookies = new Cookies();
  const http = Http.getInstance();
  const dataSource = dataSources();
  const history = useHistory();
  const [phoneNumb, setPhoneNumb] = useState(cookies.get('mobile_number'));
  const [error, setError] = useState(false);

  const translationData = JSON.parse(localStorage.getItem('translations') || '{}');

  let SmsTitle;
  let SmsText;
  let AddPhoneTitle;
  let AddPhoneText;
  let ErrorText;
  let AddPhonebtn;
  let Backbtn;

  if (translationData) {
    SmsTitle = translationData['portal-sms_title'];
    SmsText = translationData['portal-sms_text'];
    AddPhoneTitle = translationData['portal-sms_add_phone_title'];
    AddPhoneText = translationData['portal-email_add_phone_text'];
    ErrorText = translationData['portal-sms_add_phone_error_text'];
    AddPhonebtn = translationData['portal-sms_add_phone_button'];
    Backbtn = translationData['portal-sms_add_phone_back_button'];
  }

  const handlePhoneNumb = phoneNumb => {
    if (phoneNumb) {
      if (phoneNumb && phoneNumb.length > 2) {
        setPhoneNumb(phoneNumb);
      }
      if (phoneNumb.length === 2 && error == true) {
        setError(false);
      }
    }
  };

  const addPhoneNumb = () => {
    if (phoneNumb) {
      if (isPossiblePhoneNumber(phoneNumb) && isValidPhoneNumber(phoneNumb)) {
        setError(false);
        const user = {
          mobile_number: phoneNumb,
        };
        http
          .patchData(`${dataSource.users}`, user, '')
          .then(res => {
            if (res.status === 200) {
              console.log('user updated', res);
              cookies.set('mobile_number', phoneNumb, {
                path: '/',
                domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
              });
              //window.location.href="/verifyPhone";
              history.push({
                pathname: '/myaccount/mfa/verifyPhone',
                default_mfa_type:
                  (props.location.default_mfa_type !== undefined || props.location.default_mfa_type !== null) &&
                  props.location.default_mfa_type,
              });
            }
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        console.log('error');
        setError(true);
      }
    }
    // console.log(isPossiblePhoneNumber(phoneNumb) , isValidPhoneNumber(phoneNumb),isPossiblePhoneNumber(phoneNumb) && isValidPhoneNumber(phoneNumb),"isValid");
    // if(phoneNumb){
    //   console.log(error);
    //   if (error === false) {

    //   }
    // }
  };

  const authorised_user = cookies.get('authorised_user');
  if (authorised_user === 'true') {
    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <div dir="ltr">
            <AppLayout
              apps={JSON.parse(localStorage.getItem('apps'))}
              errorDisplay={localStorage.getItem('errorDisplay') === 'false' ? false : true}
              errorResponse={localStorage.getItem('error')}
            />

            <div className="totpRoot">
              <h5 className="totpTitle">
                {SmsTitle !== undefined
                  ? SmsTitle
                  : 'Multifactor Authentication setup for Phone/SMS verification'}
              </h5>

              <div className="totp">
                <div className="emailSection">
                  <img alt="user" className="userLogo" height="95px" src={userLogo} />
                  <div>
                    {SmsText !== undefined
                      ? SmsTitle
                      : 'Adding phone number authentication ensure that only authorized individuals can access information'}
                  </div>
                </div>

                <div className="otpVerifySection">
                  <img alt="email" className="emailUserLogo" src={phoneIcon} />
                  <h5>{AddPhoneTitle !== undefined ? AddPhoneTitle : 'Add your Phone Number'}</h5>
                  <div>
                    {AddPhoneText !== undefined
                      ? AddPhoneText
                      : 'Enter your Phone number to which authentication code has to be sent'}
                  </div>

                  {/* <TextField
                              className="otpField"
                              variant="outlined"
                              fullWidth
                              value={phoneNumb}
                              required
                              onChange={(e) => handlePhoneNumb(e)}
                              onKeyDown={e => {
                                if (e.keyCode === 13) {
                                    e.preventDefault();
                                    addPhoneNumb();
                                }
                              }}
                            /> */}

                  <PhoneInput
                    className="phoneNumbField"
                    international={false}
                    addInternationalOption={false}
                    value={phoneNumb}
                    defaultCountry="US"
                    countries={['IN', 'US']}
                    onChange={e => handlePhoneNumb(e)}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        addPhoneNumb();
                      }
                    }}
                  />

                  {/* 
                            <Input
                            className="phoneNumbField"
                            fullWidth
                            country="US"
                            international
                            withCountryCallingCode
                            value={phoneNumb}
                            onChange={(e) => handlePhoneNumb(e)}
                            onKeyDown={e => {
                              if (e.keyCode === 13) {
                                  e.preventDefault();
                                  addPhoneNumb();
                              }
                            }}
                            /> */}

                  {error && (
                    <div className="otpErrorMsg">
                      {ErrorText !== undefined ? ErrorText : 'Enter a valid phone number'}
                    </div>
                  )}

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      addPhoneNumb();
                    }}
                    className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable activateBtn">
                    {AddPhonebtn !== undefined ? AddPhonebtn : 'Add Phone Number'}
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      window.location.href = '/myaccount/mfa/settings';
                    }}
                    id="cancel"
                    className="classes.button MuiButton-fullWidth button-focus-disable navigateMethod">
                    {Backbtn !== undefined ? Backbtn : 'BACK'}
                  </Button>
                </div>
              </div>
            </div>

            <FooterMenu />
          </div>
        </ThemeProvider>
      </React.Fragment>
    );
  } else {
    window.location.href = '/';
  }
}
