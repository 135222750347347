import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import { SystemInfoMenu } from '../system-info-menu';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import * as staticTextKey from '../../utils/translation-variable';

export function FooterMenu(props) {
  const [copyrightValue, setcopyrightValue] = React.useState('');
  const [ppValue, setppValue] = React.useState('');
  const [infoValue, setInfoValue] = React.useState('');
  const [copyrightyearValue, setCopyrightyearValue] = React.useState('');
  const date = new Date();
  const copyrightyear = date.getFullYear();

  useEffect(() => {
    (async () => {
      await getTranslationData();
    })();
  }, []);

  const getTranslationData = () => {
    var language = window.navigator.userLanguage || window.navigator.language;
    console.log(language);
    language = 'en-US';
    const cookies = new Cookies();
    var selectedLanguageCookie = cookies.get('bni_language_select');
    console.log(selectedLanguageCookie);
    language = selectedLanguageCookie !== undefined ? selectedLanguageCookie : language;

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_I18NAPI}/translations/${language}/search`,
      data: {
        has_translation: true,
        group_code: 'portal.login',
        page_size: 100,
      },
      headers: {
        'Content-Type': 'application/json',
        client_name: `${process.env.REACT_APP_clientName}`,
        client_secret: `${process.env.REACT_APP_clientSecret}`,
        'x-iso-ids-only': 'N',
      },
    })
      .then(response => {
        const translationRawData = response.data.translations;
        let portaltranslation = {};
        translationRawData.map(function (row) {
          portaltranslation[row.lang_key] = row.lang_value ? row.lang_value : row.en_val;
        });
        //handle success
        sessionStorage.setItem('lang', JSON.stringify(language));
        sessionStorage.setItem('translations', JSON.stringify(portaltranslation));
        setcopyrightValue(portaltranslation[staticTextKey.copyrightValue]);
        setppValue(portaltranslation[staticTextKey.ppValue]);
        setInfoValue(portaltranslation[staticTextKey.infoValue]);
        setCopyrightyearValue(copyrightyear);
      })
      .catch(function (response) {
        //handle error
        console.log(response);
      });
  };


  return (
    <React.Fragment>
      <footer className="footer-container">
        {copyrightValue && copyrightyear && ppValue && infoValue !== '' ? <Box className="links">
          {copyrightValue}
          {` `}
          {copyrightyearValue} | {ppValue} |<SystemInfoMenu infoValue={infoValue} />
        </Box> : ''}
      </footer>
    </React.Fragment>
  );
}
