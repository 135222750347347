import React, { useEffect } from 'react';
import { Popover, Typography, Box, Button, Divider } from '@material-ui/core';

import * as staticTextKey from '../../utils/translation-variable';

export function SystemInfoMenu({ infoValue }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [infoText, setinfoText] = React.useState('');
  const [translateApiTitleData, settranslateApiTitleData] = React.useState('');
  const [translateApiVersionData, settranslateApiVersionData] = React.useState('');
  const [translateApiEnvironmentData, settranslateApiEnvironmentData] = React.useState('');

  const [portalApiTitleData, setportalApiTitleData] = React.useState('');
  const [portalApiVersionData, setportalApiVersionData] = React.useState('');
  const [portalApiEnvironmentData, setportalApiEnvironmentData] = React.useState('');

  //get translation data from session storage
  useEffect(() => {
    let translationData = JSON.parse(sessionStorage.getItem('translations'));
    let ApiStatusData = JSON.parse(sessionStorage.getItem('ApiStatusData'));
    let TranslateApiStatusData = JSON.parse(sessionStorage.getItem('translationApiStatusData'));
    if (translationData !== null) {
      setinfoText(translationData[staticTextKey.infoValue]);
    }
    // setApiData(ApiStatusData)

    if (ApiStatusData !== null) {
      setportalApiTitleData(ApiStatusData.title);
      setportalApiVersionData(ApiStatusData.version);
      setportalApiEnvironmentData(ApiStatusData.environment);
    }
    if (TranslateApiStatusData !== null) {
      settranslateApiTitleData(TranslateApiStatusData.title);
      settranslateApiVersionData(TranslateApiStatusData.version);
      settranslateApiEnvironmentData(TranslateApiStatusData.environment);
    }
  }, []);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'sysdetails-popover' : undefined;
  const idunderline = open ? 'underline' : undefined;

  return (
    <React.Fragment>
      <Button className="info-text" id={idunderline} aria-describedby={id} onClick={handleClick}>
        {infoValue}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Box className="pop-over-aarow">
          <Box pb={2}>
            <Typography>
              {process.env.REACT_APP_title}: {process.env.REACT_APP_myBNIversion} (
              {process.env.REACT_APP_env})
            </Typography>
          </Box>
          <Divider />
          <Box pt={2} pb={2}>
            <Typography>
              {portalApiTitleData}: {portalApiVersionData} ({portalApiEnvironmentData})
            </Typography>
          </Box>
          <Divider />
          <Box pt={1}>
            <Typography>
              {translateApiTitleData}: {translateApiVersionData} ({translateApiEnvironmentData})
            </Typography>
          </Box>
        </Box>
      </Popover>
    </React.Fragment>
  );
}
