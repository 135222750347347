import { createTheme } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';

// Create a theme instance.
const theme = createTheme({
  direction: 'ltr',
  palette: {
    primary: {
      main: '#005fcc',
    },
    secondary: {
      main: '#C8C8C8',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'none',
        },
      },
    },
    muiFocused: {
      root: {
        color: '#005fcc',
      },
    },
  },
});

export default theme;
