import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import AppsIcon from '@material-ui/icons/Apps';
import { HandleErrors } from '../../components/handle-errors';
import { BrowserRouter as Link } from 'react-router-dom';
import { Cookies } from 'react-cookie';

const useStyles = makeStyles(theme => ({
  list: {
    width: 250,
  },
  content: {
    flexGrow: 1,
    marginLeft: 20,
    '&:focus': {
      outline: 'none',
    },
  },
}));

export function AppMenu(props) {
  let cookies = new Cookies();
  const classes = useStyles();
  let apps = Object.entries(props.apps);
  let appsData = JSON.stringify(apps);

  cookies.set('apps', appsData, {
    path: '/',
    domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
  });

  return (
    <Drawer anchor="left" open={props.drawerOpened} onClose={props.toggleDrawer(false)}>
      <div className={classes.list}>
        <IconButton
          className={classes.content}
          color="inherit"
          aria-label="Menu"
          onClick={props.toggleDrawer(false)}>
          <AppsIcon style={{ fontSize: 40 }} />
        </IconButton>
        {!props.errorDisplay && (
          <div className="app-menu-container">
            {Object.keys(apps).map((key, index) => (
              <React.Fragment key={index}>
                {apps[key][1]['tos_acceptance_status'] ? (
                  <a href={apps[key][1]['url']} key={index} className="app-btn">
                    <img
                      className=""
                      src={apps[key][1]['logo_url']}
                      height="44"
                      alt={apps[key][1]['app_name']}
                    />
                  </a>
                ) : (
                  <Link
                    to={{
                      pathname: '/terms',
                      state: { appId: apps[key][1]['_id'] },
                    }}
                    rel="noopener noreferrer"
                    key={index}
                    className="app-btn">
                    <img
                      className=""
                      src={apps[key][1]['logo_url']}
                      height="44"
                      alt={apps[key][1]['app_name']}
                    />
                  </Link>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
        {props.errorDisplay && <HandleErrors errorData={props.errorResponse} />}
      </div>
    </Drawer>
  );
}
