export const staticTranslationData = [
  'portal-login-username',
  'portal-login-password',
  'portal-login-forgot_password',
  'portal-login-login',
  'portal-terms-t_and_c',
  'portal-terms-tos_and_pp',
  'portal-terms-cancel',
  'portal-terms-accept',
  'portal-launcher-logout',
  'portal-launcher-no_apps_assigned',
  'portal-launcher-copyright',
  'portal-launcher-pp',
  'portal-launcher-info',
];
