import { Component } from 'react';
import { Cookies } from 'react-cookie';
import Http from '../services/http';
import { dataSources } from '../utils/data-sources';

class OauthPage extends Component {
  http = Http.getInstance();
  dataSources = dataSources();

  componentDidMount() {
    if (document.cookie.split(';').filter(item => item.includes('access_token')).length) {
      this.getStatusData();
    } else {
      window.location.href = `${process.env.REACT_APP_myBNIURL}`;
    }
  }

  getStatusData = () => {
    const cookies = new Cookies();
    let IAM_id = cookies.get('IAM_id');
    let url = `${this.dataSources.users}/${IAM_id}`;
    return this.http
      .getData(url, '', '')
      .then(res => {
        if (res.status === 200) {
          let returnURL = window.location.href.split(`${process.env.REACT_APP_myBNIURL}`)[1];
          let access_token = cookies.get('access_token');
          let url = `${process.env.REACT_APP_BNIOauth}/${returnURL}&id_token=${access_token}`;
          window.location.href = url;
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  render() {
    return null;
  }
}

OauthPage.propTypes = {};

export default OauthPage;
