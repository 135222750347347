import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
// import * as staticTextKey from '../../utils/translation-variable';
import userLogo from '../static/imgs/userLogo.png';
import emailIcon from '../static/imgs/emailIcon.png';
import Http from '../services/http';
import { dataSources } from '../utils/data-sources';
import { TextField, Button } from '@material-ui/core';
import { FooterMenu } from '../components/footer-menu';
import { AppLayout } from '../components/app-layout';
import theme from '../utils/theme';
import { useHistory } from 'react-router-dom';
import { Cookies } from 'react-cookie';

export function Email(props) {
  const history = useHistory();
  const cookies = new Cookies();
  const http = Http.getInstance();
  const dataSource = dataSources();
  const mfaEmail =
    cookies.get('mfa_email') === 'null' || cookies.get('mfa_email') === 'undefined'
      ? ''
      : cookies.get('mfa_email');
  const [email, setEmail] = useState(mfaEmail);
  const [error, setError] = useState(false);

  const handleEmail = event => {
    if (event.target.value.length === 0) {
      setError(false);
    }
    setEmail(event.target.value);
  };

  const translationData = JSON.parse(localStorage.getItem('translations') || '{}');

  let EmailTitle;
  let EmailText;
  let AddEmailTitle;
  let AddEmailText;
  let ErrorEmailText;
  let AddEmailButton;
  let BackButton;

  if (translationData) {
    EmailTitle = translationData['portal-email_title'];
    EmailText = translationData['portal-email_text'];
    AddEmailTitle = translationData['portal-email_add_email_title'];
    AddEmailText = translationData['portal-email_add_email_text'];
    ErrorEmailText = translationData['portal-email_add_email_error_text'];
    AddEmailButton = translationData['portal-email_add_email_button'];
    BackButton = translationData['portal-email_add_email_back_button'];
  }

  const addEmail = () => {
    let regex = new RegExp('^[^._]+[a-zA-Z0-9._-]+[a-zA-Z0-9]+@[a-zA-Z0-9._-]+.[a-zA-Z]$');
    let isValid = regex.test(email.trim());
    if (isValid) {
      const user = {
        email: email,
      };
      http
        .patchData(`${dataSource.users}`, user, '')
        .then(res => {
          if (res.status === 200) {
            console.log('user mfa updated', res);
            setError(false);
            cookies.set('mfa_email', email, {
              path: '/',
              domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
            });
            //window.location.href="/verifyEmailId";
            history.push({
              pathname: '/myaccount/mfa/verifyEmailId',
              default_mfa_type:
                (props.location.default_mfa_type !== undefined || props.location.default_mfa_type !== null) &&
                props.location.default_mfa_type,
            });
          } else {
            setError(true);
          }
        })
        .catch(error => {
          console.log(error);
        });
    } else {
      setError(true);
    }
  };

  const authorised_user = cookies.get('authorised_user');
  if (authorised_user === 'true') {
    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <div dir="ltr">
            <AppLayout
              apps={JSON.parse(localStorage.getItem('apps'))}
              errorDisplay={localStorage.getItem('errorDisplay') === 'false' ? false : true}
              errorResponse={localStorage.getItem('error')}
            />

            <div className="totpRoot">
              <h5 className="totpTitle">
                {EmailTitle !== undefined
                  ? EmailTitle
                  : 'Multifactor Authentication setup for email verification'}
              </h5>

              <div className="totp">
                <div className="emailSection">
                  <img alt="user" className="userLogo" height="95px" src={userLogo} />
                  <div>
                    {EmailText !== undefined
                      ? EmailText
                      : 'Adding email and phone number authentication ensure that only authorized individuals can access information'}
                  </div>
                </div>

                <div className="otpVerifySection">
                  <img alt="email" className="emailUserLogo" src={emailIcon} />
                  <h5>{AddEmailTitle !== undefined ? AddEmailTitle : 'Add your email address'}</h5>
                  <div>
                    {AddEmailText !== undefined
                      ? AddEmailText
                      : 'Enter your mail id to which authentication code has to be sent'}
                  </div>

                  <TextField
                    className="otpField"
                    variant="outlined"
                    fullWidth
                    value={email}
                    required
                    onChange={e => handleEmail(e)}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        addEmail();
                      }
                    }}
                  />

                  {error && (
                    <div className="otpErrorMsg">
                      {ErrorEmailText !== undefined ? ErrorEmailText : 'Enter a valid email'}
                    </div>
                  )}

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      addEmail();
                    }}
                    className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable activateBtn">
                    {AddEmailButton !== undefined ? AddEmailButton : 'Add Email'}
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={() => {
                      window.location.href = '/myaccount/mfa/settings';
                    }}
                    id="cancel"
                    className="classes.button MuiButton-fullWidth button-focus-disable navigateMethod">
                    {BackButton !== undefined ? BackButton : 'BACK'}
                  </Button>
                </div>
              </div>
            </div>

            <FooterMenu />
          </div>
        </ThemeProvider>
      </React.Fragment>
    );
  } else {
    window.location.href = '/';
  }
}
