import React, { useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
// import * as staticTextKey from '../../utils/translation-variable';
import userLogo from '../static/imgs/userLogo.png';
import emailIcon from '../static/imgs/emailIcon.png';
import Http from '../services/http';
import { dataSources } from '../utils/data-sources';
import { TextField, Button, Modal, IconButton, Typography, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { FooterMenu } from '../components/footer-menu';
import { AppLayout } from '../components/app-layout';
import theme from '../utils/theme';
import Alert from '@material-ui/lab/Alert';
import { Cookies } from 'react-cookie';
import { makeStyles } from '@material-ui/core/styles';

const translationData = JSON.parse(localStorage.getItem('translations') || '{}');

let EmailTitle;
let EmailText;
let CodeTitle;
let CodeText;
let RemainingTime;
let NoReceiveCode;
let Resend;
let Verify;
let Back;
let Cancel;
let InvalidCode;
let Success;
let Verified;
let Ok;

if (translationData) {
  EmailTitle = translationData['portal-email_title'];
  EmailText = translationData['portal-email_text'];
  CodeTitle = translationData['portal-email_enter_code_activate_title'];
  CodeText = translationData['portal-email_enter_code_activate_text'];
  RemainingTime = translationData['portal-email_enter_code_remaining_time'];
  NoReceiveCode = translationData['portal-email_enter_code_didnt_recieve_code'];
  Resend = translationData['portal-email_enter_code_resend'];
  Verify = translationData['portal-email_verify_button'];
  Back = translationData['portal-email_back_button'];
  Cancel = translationData['portal-email_cancel_button'];
  InvalidCode = translationData['portal-email_enter_code_error_text'];
  Success = translationData['portal-email_enter_code_activation_success_message_title'];
  Verified = translationData['portal-email_enter_code_activation_success_message_text'];
  Ok = translationData['portal-email_enter_code_activation_success_message_ok'];
}

const useStyles = makeStyles(theme => ({
  modal: {
    position: 'absolute',
    background: 'white',
    width: 440,
    height: 470,
    boxShadow: theme.shadows[5],
  },
}));

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    height: '150px',
  };
}

export function VerifyEmailId(props) {
  const [code, setCode] = useState();
  const [error, setError] = useState(false);
  const http = Http.getInstance();
  const [over, setOver] = useState(false);
  const [[m, s], setTime] = useState([5, 0]);
  const [openAlert, setOpenAlert] = useState(false);
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  var cookies = new Cookies();
  const authorised_user = cookies.get('authorised_user');
  const dataSource = dataSources();
  const default_mfa_type =
    (props.location.default_mfa_type !== undefined || props.location.default_mfa_type !== null) &&
    props.location.default_mfa_type;

  useEffect(() => {
    var data = { type: 'email' };
    if (authorised_user === 'true') {
      http
        .postData(`${dataSource.sendOtp}`, data, '')
        .then(res => {
          console.log('res', res);
        })
        .catch(error => {
          console.log(error, 'error');
        });
    }
  }, []);

  useEffect(() => {
    const timerID = setInterval(() => tick(), 1000);
    return () => clearInterval(timerID);
  });

  const tick = () => {
    // if (over) return;
    if (m === 0 && s === 0) setOver(true);
    else if (m === 0 && s === 0) {
      setTime([59, 59]);
    } else if (s == 0) {
      setTime([m - 1, 59]);
    } else {
      setTime([m, s - 1]);
    }
  };

  const reset = () => {
    setTime([parseInt(5), parseInt(0)]);
    var data = { type: 'email' };
    http
      .postData(`${dataSource.sendOtp}`, data, '')
      .then(res => {
        console.log('res', res);
      })
      .catch(error => {
        console.log(error, 'error');
      });
  };

  const handleCode = event => {
    if (event.target.value.length === 0) {
      setError(false);
    }
    setCode(event.target.value);
  };

  const handleClose = () => {
    setOpenAlert(false);
    window.location.href = '/myaccount/mfa/settings';
  };

  const verifyCode = () => {
    let otp = { OTP: code, type: 'email' };
    http
      .postData(`${dataSource.validateOtp}`, otp, '')
      .then(res => {
        if (res.status === 200) {
          if (res.data.valid === true) {
            // setOpenAlert(true);
            setError(false);
            const user = {
              mfa_enabled: true,
              email_verified: true,
              default_mfa_type: default_mfa_type,
            };
            http
              .patchData(`${dataSource.users}`, user, '')
              .then(res => {
                if (res.status === 200) {
                  cookies.set('mfa_enabled', true, {
                    path: '/',
                    domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                  });
                  cookies.set('default_mfa_type', default_mfa_type, {
                    path: '/',
                    domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                  });
                  cookies.set('email_verified', true, {
                    path: '/',
                    domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
                  });
                  http.postData(`${dataSource.mfaMethods}/${default_mfa_type.mfa_id}`, '', '')
                  .then(res => {
                  console.log("res",res);
                  }).catch(error => {
                  console.log("error", error);
                  });
                  setOpenAlert(true);
                  // setTimeout(()=>{window.location.href="/launcher"},3000);
                }
              })
              .catch(error => {
                console.log(error);
              });
          } else if (res.data.valid === false) {
            console.log('invalid otp');
            setOpenAlert(false);
            setError(true);
          }
        } else {
          setOpenAlert(false);
          setError(true);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  if (authorised_user === 'true') {
    console.log('props', props);
    return (
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <div dir="ltr">
            <AppLayout
              apps={JSON.parse(localStorage.getItem('apps'))}
              errorDisplay={localStorage.getItem('errorDisplay') === 'false' ? false : true}
              errorResponse={localStorage.getItem('error')}
            />

            {/* { verified && <Alert 
                                icon={false} 
                                className="successNotification"
                                onClose={() => {setVerified(false)}}>
                                  Your Email is successfully verified!
                                </Alert> 
                  } */}

            <Modal
              open={openAlert}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description">
              <div style={modalStyle} className={classes.modal}>
                <div className={'dialogHeader'}>
                  <Typography color="textPrimary" className={'dialogTitle'}>
                    {Success !== undefined ? Success : 'SUCCESS'}
                  </Typography>
                  <IconButton onClick={handleClose} className={'closeButton'}>
                    <CloseIcon />
                  </IconButton>
                </div>
                <Divider />
                <div className={'successMsg'}>
                  {Verified !== undefined ? Verified : 'Your Email is successfully verified!'}
                </div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    handleClose();
                  }}
                  className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable actionBtn">
                  {Ok !== undefined ? Ok : 'OK'}
                </Button>
              </div>
            </Modal>

            <div className="totpRoot">
              <h5 className="totpTitle">
                {EmailTitle !== undefined
                  ? EmailTitle
                  : 'Multifactor Authentication setup for email verification'}
              </h5>

              <div className="totp">
                <div className="emailSection">
                  <img alt="user" className="userLogo" height="95px" src={userLogo} />
                  <div>
                    {EmailText !== undefined
                      ? EmailText
                      : 'Adding email and phone number authentication ensure that only authorized individuals can access information'}
                  </div>
                </div>

                <div className="otpVerifySection">
                  <img alt="email" className="emailUserLogo" src={emailIcon} />
                  <h5>
                    {CodeTitle !== undefined
                      ? CodeTitle
                      : 'Enter user authentication code sent to your email'}
                  </h5>
                  <div>
                    {CodeText !== undefined
                      ? CodeText
                      : 'Enter the 6 digit code to add and verify email'}
                  </div>

                  <TextField
                    className="otpField"
                    variant="outlined"
                    fullWidth
                    value={code}
                    required
                    onChange={e => handleCode(e)}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        e.preventDefault();
                        verifyCode();
                      }
                    }}
                  />

                  {error && (
                    <div className="otpErrorMsg">
                      {InvalidCode !== undefined
                        ? InvalidCode
                        : 'Invalid user authentication code. Please retry!'}
                    </div>
                  )}

                  <div className="timer">
                    <div>
                      {RemainingTime !== undefined ? RemainingTime : 'Remaining time:'}
                      {`${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`}
                    </div>
                    <div>
                      {NoReceiveCode !== undefined ? NoReceiveCode : "didn't receive code?"}
                      <button className="resendBtn" onClick={() => reset()}>
                        {Resend !== undefined ? Resend : 'Resend again'}
                      </button>
                    </div>
                  </div>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      verifyCode();
                    }}
                    disabled={!s || code === undefined || code === null}
                    className="bni-background-color classes.button MuiButton-fullWidth button-focus-disable activateBtn emailVerifyBtn">
                    {Verify !== undefined ? Verify : 'Verify'}
                  </Button>

                  <div className="navigateParent">
                    <Button
                      variant="outlined"
                      onClick={() => {
                        window.location.href = '/myaccount/mfa/email';
                      }}
                      id="cancel"
                      className="classes.button MuiButton-fullWidth button-focus-disable navigates">
                      {Back !== undefined ? Back : 'BACK'}
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        window.location.href = '/myaccount/mfa/settings';
                      }}
                      id="cancel"
                      className="classes.button MuiButton-fullWidth button-focus-disable navigates">
                      {Cancel !== undefined ? Cancel : 'CANCEL'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>

            <FooterMenu />
          </div>
        </ThemeProvider>
      </React.Fragment>
    );
  } else {
    window.location.href = '/';
  }
}
