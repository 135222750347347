import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
  circularProgress: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(-3),
  },
}));

function ContainedButtons(props) {
  const classes = useStyles();
  return (
    <div>
      <Button variant="contained" className="MuiButton-fullWidth" size="large" disabled>
        {props.text}
        <CircularProgress className={classes.circularProgress} size={20} />
      </Button>
    </div>
  );
}

export default ContainedButtons;
