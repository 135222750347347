import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

export function HandleErrors(props) {
  return (
    <React.Fragment>
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {props.errorData === undefined ? (
          <React.Fragment>Error! Please contact technical support.</React.Fragment>
        ) : (
          <React.Fragment>{props.errorData.data.msg}</React.Fragment>
        )}
      </Alert>
    </React.Fragment>
  );
}
