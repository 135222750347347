import React, { useEffect, useState } from 'react';
import Http from '../services/http';
import { Redirect } from 'react-router-dom';
import { LoginLayout } from '../components/login-layout/login-layout-new';
import logo from '../static/imgs/BNIportal_logo_RGB_vertical.png';
import { Grid, Typography, Button, TextField, Paper } from '@material-ui/core';
import lockImg from '../static/imgs/lockImg.png';
import { staticTranslationData } from '../utils/static-translation-text';
import * as staticTextKey from '../utils/translation-variable';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import { dataSources } from '../utils/data-sources';

// const translationData = JSON.parse(localStorage.getItem('VerifyTranslations') || '{}');

// let CodeTitle;
// let CodeText;
// let Loginbtn;
// let Cancelbtn;

// if (translationData) {
//   CodeTitle = translationData['portal-login-totp_verify_title'];
//   CodeText = translationData['portal-login-totp_verify_text'];
//   Loginbtn = translationData['portal-login-totp_verify_login_button'];
//   Cancelbtn = translationData['portal-login-totp_verify_cancel_button'];
// }

export function VerificationPage(props) {
  var cookies = new Cookies();
  const http = Http.getInstance();
  const dataSource = dataSources();
  const [otp, setOtp] = useState('');
  const [error, setError] = useState(false);
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [translations, setTranslations] = useState({
    CodeTitle: 'Enter Authentication code',
    CodeText: 'Enter the code from Authenticator app',
    Loginbtn: 'LOGIN',
    Cancelbtn: 'CANCEL',
    invalidOTP: 'Invalid user authentication code. Please retry!',
  });

  useEffect(() => {
    const getTranslationDataMFA = async () => {
      try {
        let locale = http.getLocale();
        const cookies = new Cookies();
        const dataSource = dataSources();
        let bniLang = cookies.get('bni_language_select');

        let lang = bniLang !== undefined ? bniLang : locale.lang_code;
        axios({
          method: 'post',
          url: `${dataSource.translateApi}/${lang}/search`,
          data: {
            has_translation: true,
            group_code: 'portal-mfa',
            page_size: 100,
          },
          headers: {
            'Content-Type': 'application/json',
            client_name: `${process.env.REACT_APP_clientName}`,
            client_secret: `${process.env.REACT_APP_clientSecret}`,
            'x-iso-ids-only': 'N',
          },
        }).then(response => {
          const translationRawData = response.data.translations;

          let portalMFAtranslation = {};
          translationRawData.map(function (row) {
            portalMFAtranslation[row.lang_key] = row.lang_value ? row.lang_value : row.en_val;
          });
          localStorage.setItem('lang', JSON.stringify(lang));
          localStorage.setItem('VerifyTranslations', JSON.stringify(portalMFAtranslation));
          setTranslations({
            CodeTitle:
              portalMFAtranslation['portal-login-totp_verify_title'] || 'Enter Authentication code',
            CodeText:
              portalMFAtranslation['portal-login-totp_verify_text'] ||
              'Enter the code from Authenticator app',
            Loginbtn: portalMFAtranslation['portal-login-totp_verify_login_button'] || 'LOGIN',
            Cancelbtn: portalMFAtranslation['portal-login-totp_verify_cancel_button'] || 'CANCEL',
            invalidOTP:
              portalMFAtranslation['portal-login_mfa_enter_code_error_text'] ||
              'Invalid user authentication code. Please retry!',
          });
        });
      } catch (error) {
        //handle error
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getTranslationDataMFA();
  }, []);

  const handleOtp = event => {
    if (event.target.value.length === 0) {
      setError(false);
    }
    setOtp(event.target.value);
  };

  const handleCancel = () => {
    window.location.href = '/';
  };

  const onSuccessfullLogin = () => {
    const code = { code: otp };
    http
      .postData(`${dataSource.validateTotp}`, code, '')
      .then(res => {
        if (res.status === 200) {
          if (res.data.valid === true) {
            console.log('otp verified');
            setError(false);
            cookies.set('authorised_user', true, {
              path: '/',
              domain: `${process.env.REACT_APP_API_SERVER_sub_domian}`,
            });
            setVerified(true);
          } else if (res.data.valid === false) {
            console.log('invalid otp');
            setError(true);
            setVerified(false);
          }
        } else if (res.status === 400) {
          setError(true);
          setVerified(false);
        }
      })
      .catch(error => {
        console.log(error);
        setVerified(false);
      });
  };

  const default_mfa_type = cookies.get('default_mfa_type');
  const loggedIn = cookies.get('loggedIn');

  if (loading) {
    return null;
  }
  console.log('Rendering component with translations:');
  if (loggedIn === 'true' && default_mfa_type.type.toLowerCase() === 'totp') {
    if (verified) {
      return (
        <Redirect
          to={{
            pathname: '/launcher',
          }}
        />
      );
    } else {
      return (
        <React.Fragment>
          <LoginLayout title="Login">
            <Paper elevation={3}>
              <div className="container-wrap">
                <form noValidate>
                  <Grid container spacing={2}>
                    <Grid item sm={12}>
                      <div className=" form-text-color">
                        <img className="img-center" src={logo} alt="BNI" height="120px" />
                      </div>
                    </Grid>
                    <Grid item sm={12}>
                      <img className="lockImg" alt="lock icon" src={lockImg} />
                    </Grid>

                    <Grid item sm={12}>
                      <div className="verifyInstruction">{translations.CodeTitle}</div>
                      <Typography
                        className="terms-align-center login-top-padding login-bottom-padding verifyMsg"
                        color="textSecondary">
                        {translations.CodeText}
                      </Typography>
                    </Grid>

                    <Grid item sm={12}>
                      <div className="form-group">
                        <TextField
                          id="otpField"
                          variant="outlined"
                          fullWidth
                          value={otp}
                          onChange={e => handleOtp(e)}
                          onKeyDown={e => {
                            if (e.keyCode === 13) {
                              e.preventDefault();
                              onSuccessfullLogin();
                            }
                          }}
                        />
                      </div>
                    </Grid>

                    <Grid item sm={12}>
                      {error && <div className="loginOtpErrorMsg">{translations.invalidOTP}</div>}
                    </Grid>

                    <Grid item sm={12}>
                      <div className="form-align-center">
                        <Button
                          variant="contained"
                          color="primary"
                          className="bni-background-color MuiButton-fullWidth button-focus-disable"
                          size="large"
                          onClick={() => onSuccessfullLogin()}
                          id="login">
                          {translations.Loginbtn}
                        </Button>

                        <Button
                          variant="outlined"
                          className=" classes.button MuiButton-fullWidth button-focus-disable loginCancel"
                          onClick={() => handleCancel()}
                          id="cancel">
                          {translations.Cancelbtn}
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </form>
              </div>
            </Paper>
          </LoginLayout>
        </React.Fragment>
      );
    }
  } else {
    window.location.href = '/';
  }
}
