import React from 'react';
import {
  makeStyles,
  AppBar,
  CssBaseline,
  Toolbar,
  Typography,
  IconButton,
} from '@material-ui/core';

import AppsIcon from '@material-ui/icons/Apps';
import bniLogo from '../../static/imgs/BNIportal_logo_RGB_white_horizontal.png';

import { AppMenu } from '../app-menu';
import { ProfileMenu } from '../profile-menu';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: '#cf2030',
    height: 70,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  brand: {
    flexGrow: 1,
    paddingTop: 5,
  },
  textCase: {
    textTransform: 'none',
    fontSize: 18,
    '&:hover': {
      backgroundColor: 'transparent',
      cursor: 'default',
    },
  },
  toolBar: {
    paddingLeft: 16,
  },
  iconButton: {
    '&:focus': {
      outline: 'none',
    },
  },
}));

export function AppLayout(props) {
  const classes = useStyles();
  const [drawerOpened, setDrawer] = React.useState(false);

  const toggleDrawer = booleanValue => () => {
    setDrawer(booleanValue);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <IconButton
            className={classes.iconButton}
            color="inherit"
            aria-label="Menu"
            onClick={toggleDrawer(true)}>
            <AppsIcon style={{ fontSize: 40 }} />
          </IconButton>
          <a href=" /launcher ">
            <img className="brand" src={bniLogo} alt="BNI Portal" width="200" height="41" />{' '}
          </a>
          <Typography className="tagline" variant="h5" noWrap></Typography>
          <ProfileMenu logoutValue={props.logoutValue} />
        </Toolbar>
      </AppBar>
      <AppMenu
        drawerOpened={drawerOpened}
        toggleDrawer={toggleDrawer}
        apps={props.apps}
        errorDisplay={props.errorDisplay}
        errorResponse={props.errorResponse}
      />
      <main className={classes.content}>
        <Toolbar />
        {props.children}
      </main>
    </div>
  );
}
