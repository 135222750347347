import React from 'react';
import PropTypes from 'prop-types';
import image from '../../static/imgs/super_graphic.svg';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundImage: `url(${image})`,
    backgroundPosition: 'right bottom',
    backgroundRepeat: 'no-repeat',
    minHeight: '100vh',
  },
  layout: {
    width: 'auto',
    margin: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 500,
      margin: 'auto',
    },
  },
}));

export function LoginLayout(props) {
  const classes = styles();
  return (
    <React.Fragment>
      <div className={classes.root}>
        <main className={classes.layout}>{props.children}</main>
      </div>
    </React.Fragment>
  );
}

LoginLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  env: PropTypes.object,
};
