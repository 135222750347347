import { Component } from 'react';
import Http from '../services/http';

class LogoutPage extends Component {
  http = Http.getInstance();

  componentDidMount() {
    let url = `${process.env.REACT_APP_myBNIURL}`;
    this.http.destroy().then(viewData => {
      window.location.href = url;
    });
  }
  render() {
    return null;
  }
}

LogoutPage.propTypes = {};

export default LogoutPage;
